import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import MMCta from "../../components/commons/MMCTA";
import {BGSVG} from "../../components/pages/demo/BGSVG";
import "../styles.scss";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const DemoPage = ({data: cmsData}) => {
  // in mobile view, we are not supposed to show this page, so redirecting to home
  React.useEffect(() => {
    if (window.innerWidth < 800) {
      window.location.href = "/";
    }
  }, []);
  const seoData = cmsData.allSanitySeoMeta.nodes[0];
  const {pwEmbedUrl, heading, description, pCTA, sCTA} =
    cmsData.allSanityProductWalkthroughPage.nodes[0] ?? {};
  const {lastSection} = cmsData.allSanityModemagic3Page.nodes[0] ?? {};

  return (
    <Layout
      type='modemagic'
      mmv3
      seoData={seoData}
      headerProps={{showMMCta: true}}
    >
      <StructuredData />
      <Buffer buffer={81} mobileBuffer={30} />

      {/* Walkthrough Section */}
      <section className={styler.get("walkthroughWrap", "full-bleed")}>
        <BGSVG className={styler.get("bgSvg")} />
        <div className={styler.get("embedContainer")}>
          <iframe
            src={pwEmbedUrl}
            loading='lazy'
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
            title='ModeMagic - Store Conversions'
          />
        </div>
      </section>

      {/* Hero Section */}
      <section className={styler.get("heroWrap", "full-bleed")}>
        <h1 className={styler.get("heading")}>{heading}</h1>
        <div className={styler.get("description")}>{description}</div>
        <div className={styler.get("heroCtaContainer")}>
          <MMCta
            cta={pCTA?.text}
            href={pCTA?.url}
            v3
            footer={false}
            id={pCTA?.idAttribute || "install-mm-app"}
          />
          <OutboundLink
            className={styler.get("scheduleCta")}
            href={sCTA?.url}
            target='_blank'
            referrerPolicy='no-referrer'
            rel='noopener noreferrer'
            id={sCTA?.idAttribute}
          >
            {sCTA?.text}
          </OutboundLink>
        </div>
      </section>

      {/* Footer Section */}
      <section className={styler.get("footerWrap", "full-bleed")}>
        <LastSection data={lastSection} hideTicketSectionInDesktop />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "demo"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityProductWalkthroughPage {
      nodes {
        pwEmbedUrl
        heading
        description
        pCTA {
          text
          url
          idAttribute
        }
        sCTA {
          text
          url
          idAttribute
        }
      }
    }
    allSanityModemagic3Page: allSanityModemagicPage(
      filter: {page: {eq: "mmv3"}}
    ) {
      nodes {
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          placeholder
        }
      }
    }
  }
`;

export default DemoPage;
